import request from '@/utils/request-biometric'
export function search (params) {
  return request({
    url: '/bio-subject/search',
    method: 'post',
    params
  })
}
export function getPage (page, noitems, params) {
  return request({
    url: '/bio-subject/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}

export function getBioSubject (id) {
  return request({
    url: `bio-subject/${id}`,
    method: 'get'
  })
}

export function updateBioSubject (data, id) {
  return request({
    url: `bio-subject/${id}`,
    method: 'put',
    data
  })
}

export function deleteBioSubject (id) {
  return request({
    url: `/bio-subject/${id}`,
    method: 'delete'
  })
}

export function updateStatusBioSubject (id) {
  return request({
    url: `bio-subject/status/change/${id}`,
    method: 'put'
  })
}

export function exportExcel (data) {
  return request({
    url: 'bio-subject/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
